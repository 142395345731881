<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="订单号：">
        <el-input size="small" v-model="searchForm.order_sn"></el-input>
      </el-form-item>
      <el-form-item label="支付时间：">
        <el-date-picker size="small" v-model="searchForm.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="支付状态：">
        <el-select v-model="searchForm.type" size="small" placeholder="请选择">
          <el-option v-for="item in typeList" :key="item.status" :label="item.name" :value="item.status"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收款人身份：">
        <el-select v-model="searchForm.is_type" size="small" placeholder="请选择">
          <el-option v-for="item in isTypeList" :key="item.status" :label="item.name" :value="item.status"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
        <el-button size="small" @click="exportFn">导出</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="收款方信息" align="center" width="200px">
        <template v-slot="{ row }">
          <UserInfo :avatar="row.cicle_id ? row.cicle_info.logo : row.store_info.store_logo" :name="row.cicle_id ? row.cicle_info.title : row.store_info.store_name"></UserInfo>
        </template>
      </el-table-column>
      <!-- <el-table-column label="收款方电话" align="center">
        <template v-slot="{ row }">{{ row.cicle_id ? '' : '' }}</template>
      </el-table-column> -->
      <el-table-column label="收款方身份" align="center">
        <template v-slot="{ row }">{{ row.cicle_id ? '圈主' : '商家' }}</template>
      </el-table-column>
      <el-table-column label="付款人" align="center" width="200px">
        <template v-slot="{ row }"><UserInfo :avatar="row.user_info.avatar" :name="row.user_info.nickname"></UserInfo></template>
      </el-table-column>
      <el-table-column label="付款人电话" align="center">
        <template v-slot="{ row }">{{ row.user_info.mobile }}</template>
      </el-table-column>
      <el-table-column label="付款金额" align="center">
        <template v-slot="{ row }">¥{{ row.amount }}</template>
      </el-table-column>
      <!-- <el-table-column label="订单状态" align="center">
        <template v-slot="{ row }">{{ getTypeName(row.status) }}</template>
      </el-table-column> -->
      <el-table-column label="付款时间" align="center" width="200px">
        <template v-slot="{ row }">
          {{ row.create_time ? getDateformat(row.create_time) : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="退款金额" align="center">
        <template v-slot="{ row }">¥{{ row.refund_amount || '--' }}</template>
      </el-table-column>
      <el-table-column label="退款状态" align="center">
        <template v-slot="{ row }">{{ row.refund_state ? getRefundText(row.refund_state) : '--' }}</template>
      </el-table-column>
      <el-table-column label="退款时间" align="center" width="200px">
        <template v-slot="{ row }">
          {{ row.refund_state ? getDateformat(row.refund_time) : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="备注状态" align="center" fixed="right">
        <template v-slot="{ row }">
          {{ row.is_remark ? row.reason : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="isRemakFn(row)" type="text">{{ row.is_remark ? '取消备注' : '添加备注' }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <!-- 展示添加备注弹窗 -->
    <el-dialog title="添加备注" :visible.sync="addRemarkFlag">
      <el-form label-width="120px">
        <el-form-item label="收款方信息">
          <UserInfo
            v-if="updateDataItem.id"
            :avatar="updateDataItem.cicle_id ? updateDataItem.cicle_info.logo : updateDataItem.store_info.store_logo"
            :name="updateDataItem.cicle_id ? updateDataItem.cicle_info.title : updateDataItem.store_info.store_name"
          ></UserInfo>
        </el-form-item>
        <el-form-item label="收款方身份">
          {{ updateDataItem.cicle_id ? '圈主' : '商家' }}
        </el-form-item>
        <el-form-item label="付款人">
          <UserInfo v-if="updateDataItem.id" :avatar="updateDataItem.user_info.avatar" :name="updateDataItem.user_info.nickname"></UserInfo>
        </el-form-item>
        <el-form-item label="付款金额">¥{{ updateDataItem.amount }}</el-form-item>
        <el-form-item label="付款时间">
          {{ updateDataItem.create_time ? getDateformat(updateDataItem.create_time) : '--' }}
        </el-form-item>
        <el-form-item label="备注">
          <el-input style="width: 500px" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入备注" v-model="remarkReson"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addRemarkFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureRemark">确 定</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import UserInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate.js';

export default {
  components: {
    Paging,
    UserInfo,
  },
  data() {
    return {
      getDateformat,
      searchForm: {
        page: 1,
        rows: 10,
        type: -1,
        time: [],
        order_sn: '',
        is_type: 0,
      },
      dataList: [],
      total: 0,
      typeList: [
        { status: -1, name: '全部' },
        { status: 0, name: '无效订单' },
        { status: 1, name: '待结算' },
        { status: 2, name: '已结算' },
      ],
      refundStateList: [
        { status: 0, name: '' },
        { status: 1, name: '退款中' },
        { status: 2, name: '退款成功' },
        { status: -1, name: '退款失败' },
      ],
      isTypeList: [
        { status: 0, name: '全部' },
        { status: 1, name: '圈主' },
        { status: 2, name: '商家' },
      ],
      remarkReson: '',
      addRemarkFlag: false,
      updateDataItem: {
        cicle_info: {},
        store_info: {},
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    isRemakFn(data) {
      if (data.is_remark) {
        this.$confirm('是否取消对该订单的备注', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.operationRemark(data);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      } else {
        this.updateDataItem = data;
        this.addRemarkFlag = true;
      }
    },
    sureRemark() {
      if (!this.remarkReson) {
        this.$message.warning('请输入备注信息');
        return;
      }
      this.operationRemark(this.updateDataItem);
    },
    operationRemark(data) {
      let obj = {
        id: data.id,
        is_remark: data.is_remark ? 0 : 1,
      };
      if (!data.is_remark) {
        obj.reason = this.remarkReson;
      }
      this.$axios.post(this.$api.serviceProvider.isRemark, obj).then(res => {
        if (res.code == 0) {
          if (data.is_remark) {
            data.is_remark = 0;
          } else {
            data.is_remark = 1;
            data.reason = this.remarkReson;
            this.addRemarkFlag = false;
          }
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    getTypeName(type) {
      if (type == 4) return '无效订单';
      let data = this.typeList.find(item => item.status === Number(type));
      if (data === undefined) data = { name: '异常' };
      return data.name;
    },
    getRefundText(type) {
      let data = this.refundStateList.find(item => item.status === Number(type));
      if (data === undefined) data = { name: '异常' };
      return data.name;
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getDataList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        order_sn: '',
        type: -1,
        time: [],
      };
      this.getDataList();
    },
    getDataList(style) {
      if (style == 1) this.searchForm.page = 1;
      let obj = {
        page: this.searchForm.page,
        rows: this.searchForm.rows,
      };
      if (this.searchForm.order_sn) obj.order_sn = this.searchForm.order_sn;
      if (this.searchForm.is_type) obj.is_type = this.searchForm.is_type;
      if (this.searchForm.type != -1) obj.type = this.searchForm.type;
      if (this.searchForm.time?.length) {
        obj.start_time = Math.ceil(this.searchForm.time[0].getTime() / 1000);
        obj.end_time = Math.ceil(this.searchForm.time[1].getTime() / 1000);
        obj.end_time = obj.start_time == obj.end_time ? obj.end_time + 60 * 60 * 24 - 1 : obj.end_time;
      }
      this.$axios.post(this.$api.serviceProvider.payorderList, obj).then(res => {
        if (res.code === 0) {
          this.dataList = res.result.list;
          this.total = res.result.total_number;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    exportFn() {
      let obj = {};
      if (this.searchForm.order_sn) obj.order_sn = this.searchForm.order_sn;
      if (this.searchForm.is_type) obj.is_type = this.searchForm.is_type;
      if (this.searchForm.type != -1) obj.type = this.searchForm.type;
      if (this.searchForm.time?.length) {
        obj.start_time = Math.ceil(this.searchForm.time[0].getTime() / 1000);
        obj.end_time = Math.ceil(this.searchForm.time[1].getTime() / 1000);
        obj.end_time = obj.start_time == obj.end_time ? obj.end_time + 60 * 60 * 24 - 1 : obj.end_time;
      }
      this.$axios.post(this.$api.serviceProvider.exportExel, obj).then(res => {
        if (res.code === 0) {
          this.$alert('导出中，请耐心等待，稍后跳转到导出文件中下载', '提示', {
            confirmButtonText: '确定',
            callback: action => {},
          });
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
